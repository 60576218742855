.account-manage-content {
  margin: 10px 0;
  display: flex;
  justify-content: center;
}

.account-manage-modal {
  text-align: start;
}

.account-manage-modal-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.account-manage-modal-button-container > button {
  width: fit-content;
  margin: 1rem 0;
}

.account-manage-modal-confirm-container {
  display: flex;
}
