.practice-domains-list {
  margin: 0 3px;
}

.practice-domains-list-item {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 30px; */
}

.practice-domains-list-item h5 {
  font-size: 18px;
}

.practice-domains-list-hr {
  background-color: var(--wnp-color-secondary-extralight);
  border: none;
  height: 1px;
  margin: 15px 0;
}

.practice-domains-list-progress-container {
  width: 70%;
}

.practice-domains-list-progress-label,
.practice-domains-list-score-label {
  color: var(--wnp-color-secondary-light);
  font-size: 14px;
}

.practice-domains-list-main-content {
  width: 90%;
}

.practice-domains-list-button-container {
  margin-top: 10px;
}

.practice-domains-list-score-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 80px;
}

.practice-domains-pratice-test-number {
  align-items: center;
  display: flex;
}

.practice-domains-pratice-test-number > p {
  margin-right: 5px;
}

.practice-domains-pratice-test-buttons {
  margin-top: 30px;
}

.practice-domains-pratice-test-buttons > button {
  margin: 0 15px;
  width: 100px;
}

.practice-domains-description {
  margin-bottom: 20px;
}
