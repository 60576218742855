.practice-subscribe-cert-button-list-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
}

.practice-subscribe-cert-button-wrapper {
    margin: 10px 0;
    width: 100%;
    max-width: 280px;
}
.practice-subscribe-cert-button-wrapper > button {
    width: 100%;
}