.login {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
}

.login-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  max-width: 340px;
  width: 80%;
}

.login-form > * {
  margin: 10px 0 !important;
  width: 100%;
}

.login-divider {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
}
.login-divider .line {
  height: 1px;
  background-color: black;
  width: 40%;
  margin: 5px;
}

@media only screen and (max-width: 80vw) {
  .login {
    height: 100%;
    margin-top: calc(calc(0px - var(--topbar-height)) - 30px);
  }
}
