.practice-full-open-start-form-button > button {
  width: 100%;
}

.practice-full-history-hr {
  background-color: var(--wnp-color-secondary-extralight);
  border: none;
  height: 1px;
  margin: 25px 0;
}

.practice-full-history-title {
  margin-bottom: 10px;
}

.practice-full-detail {
  font-size: 18px;
  margin-bottom: 15px;
  padding: 0 10%;
}
.practice-full-refresh-button{
  text-align: center;
  margin-top: 20px;
}

.account-manage-modal-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.account-manage-modal-button-container > button {
  width: fit-content;
  margin: 1rem 0;
}

.practice-full-detail-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.practice-full-detail-list-item {
  align-items: center;
  display: flex;
  width: 100%;
}

.practice-full-detail-list-item-field,
.practice-full-detail-list-item-value {
  padding: 6px 5px;
  width: 50%;
}

.practice-full-detail-list-item-field {
  text-align: right;
}

.practice-full-detail-list-item-value {
  text-align: left;
}

.practice-full-start-button-container {
  display: flex;
  margin-top: 30px;
}

.practice-full-start-button-container > button {
  margin: 0 15px;
  width: 100px;
}

.practice-full-history-list-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  width: 100%;
}

.practice-full-history-list-item-data-list {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0 10px;
}

.practice-full-description {
  margin-bottom: 20px;
}
