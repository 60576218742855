.signup {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}

@media only screen and (max-width: 80vw) {
  .signup {
    height: 100%;
    margin-top: calc(calc(0px - var(--topbar-height)) - 30px);
  }
}
