.home {
    margin-bottom: 10px;
}

.home-header-container {
    align-items: center;
    background-image: linear-gradient(
        150deg, 
        var(--wnp-color-primary), 
        var(--wnp-color-primary), 
        var(--wnp-color-primary-light), 
        var(--wnp-color-primary-extralight)
    );
    color: #fff;
    display: flex;
    flex-direction: column;
}

.home-header-title {
    margin: 30px 15px 0px 15px;
}

.home-header-title-1 {
    font-size: 22px;
}

.home-header-title-2 {
    font-size: 32px;
}

.home-header-bkg-image-clip-container {
    --header-bkg-image-clip-height: 190px;
    height: var(--header-bkg-image-clip-height);
    overflow: hidden;
    width: 100%;
    justify-content: center;
    display: flex;
}

.home-header-bkg-image {
    height: 530px;
    position: relative;
    bottom: 37%;
    right: -10px;
}

.home-subheader-container {
    align-items: center;
    background-color: var(--wnp-color-secondary);
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px 0;
}

.home-subheader {
    padding: 10px 0;
    width: 95%;
}

.home-cert-bubble-container {
    --cert-bubble-size: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: calc(0px - calc(var(--cert-bubble-size) / 4));
}

.home-cert-bubble {
    align-items: center;
    background-color: #fff;
    border-radius: calc(var(--cert-bubble-size) / 2);
    border-style: solid;
    border-width: 2px;
    box-shadow: 1px 1px 5px var(--wnp-color-secondary-light);
    display: flex;
    font-size: 16px;
    height: var(--cert-bubble-size);
    margin: 0 2px;
    justify-content: center;
    width: var(--cert-bubble-size);
}

.home-cert-bubble-cissp {
    border-color: var(--wnp-color-cissp);
    color: var(--wnp-color-cissp);        
}
.home-cert-bubble-cissp-2024 {
    border-color: var(--wnp-color-cissp-2024);
    color: var(--wnp-color-cissp-2024);        
}
.home-cert-bubble-ccsp {
    border-color: var(--wnp-color-ccsp);
    color: var(--wnp-color-ccsp);
}
.home-cert-bubble-crisc2024 {
    border-color: var(--wnp-color-crisc2024);
    color: var(--wnp-color-crisc2024);        
}
.home-cert-bubble-sscp {
    border-color: var(--wnp-color-sscp);
    color: var(--wnp-color-sscp);
}
.home-cert-bubble-cism {
    border-color: var(--wnp-color-cism);
    color: var(--wnp-color-cism);
}
.home-cert-bubble-ccsk {
    border-color: var(--wnp-color-ccsk);
    color: var(--wnp-color-ccsk);
}


.home-feature-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.home-feature {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.home-feature-image {
    height: 75px;
    margin-top: 20px;
}

.home-feature-text {
    margin: 10px;
}

@media only screen and (min-width: 348px) {
    .home-header-title {
        margin: 30px 0 -90px 0;
    }
    
    .home-header-title-1 {
        font-size: 26px;
    }
    
    .home-header-title-2 {
        font-size: 40px;
        width: 50%;
    }
    
    .home-header-bkg-image {
        right: -76px;
    }

    .home-subheader-container {
        padding: 25px 0;
    }

    .home-subheader {
        width: 85%;
    }

    .home-cert-bubble-container {
        --cert-bubble-size: 60px;
    }

    .home-cert-bubble {
        margin: 0 5px;
    }

    .home-feature-container {
        margin-top: 15px;
    }

    .home-feature {
        flex-direction: row;
        margin: 15px;
        max-width: 355px;
    }

    .home-feature-image {
        margin-top: 0;
    }

    .home-feature-text {
        margin: 8px 0 0 10px;
    }
}

@media only screen and (min-width: 600px) {
    .home-header-title {
        margin: 75px 0 -117px -180px;
    }

    .home-header-title-1 {
        font-size: 28px;
    }

    .home-header-title-2 {
        font-size: 44px;
        width: 100%;
    }

    .home-header-bkg-image {
        right: -180px;
    }

    .home-subheader {
        font-size: 18px;
    }

    .home-cert-bubble-container {
        --cert-bubble-size: 75px;
        margin-top: calc(0px - calc(var(--cert-bubble-size) / 5));
    }

    .home-cert-bubble {
        border-width: 3px;
        font-size: 18px;
        margin: 0 10px;
    }

    .home-feature-container {
        margin-top: 20px;
    }

    .home-feature {
        margin: 20px;
        max-width: 470px;
    }

    .home-feature-image {
        height: 80px;
    }

    .home-feature-text {
        margin: 8px 0 0 15px;
    }
}

@media only screen and (min-width: 1000px) {
    .home-header-title {
        margin: 130px 0 -219px -280px;
    }

    .home-header-title-1 {
        font-size: 40px;
    }

    .home-header-title-2 {
        font-size: 60px;
    }

    .home-header-bkg-image-clip-container {
        --header-bkg-image-clip-height: 363px;
    }

    .home-header-bkg-image {
        height: 700px;
        bottom: 26%;
        right: -280px;
    }

    .home-subheader-container {
        flex-direction: row;
        justify-content: center;
        padding: 25px 0 33px 0;
    }

    .home-subheader {
        padding: 0 2px;
        width: auto;
    }

    .home-cert-bubble-container {
        --cert-bubble-size: 90px;
        /* margin-top: 15px; */
        margin-top: calc(0px - calc(var(--cert-bubble-size) / 6));
    }

    .home-cert-bubble {
        border-width: 4px;
        font-size: 22px;
        margin: 0 15px;
    }

    .home-feature-container {
        flex-direction: row;
        justify-content: center;
        margin-left: 10px;
    }

    .home-feature {
        margin: 20px;
        width: 470px;
    }
}

@media only screen and (min-width: 1500px) {
    .home-header-title {
        margin: 130px 0 -219px -320px;
    }

    .home-header-bkg-image {
        right: -320px;
    }
}