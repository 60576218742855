.modal-container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 1310;
}

.modal {
  background-color: #fff;
  border-radius: 6px;
  min-width: 280px;
  max-width: 500px;
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 20px;
  text-align: center;
  position: relative;
  z-index: 1320;
}

.modal-header {
  align-items: center;
  border-bottom: 1px solid var(--wnp-color-secondary-extralight);
  display: flex;
  justify-content: space-between;
  margin-top: -17px;
  padding-bottom: 10px;
  width: 100%;
}

.modal-header-title {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.modal-close-button {
  background: #fff;
  border: none;
  cursor: pointer;
  outline: none;
  margin: 0;
}

.modal-close-button > svg {
  transition: all 0.2s ease-in-out;
}

.modal-close-button:hover > svg {
  color: var(--wnp-color-primary);
}

.modal-children {
  max-height: 75vh;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

@media only screen and (min-width: 480px) {
  .modal {
    padding: 30px;
  }
  .modal-header {
    margin-top: -8px;
  }
}
