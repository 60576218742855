.signup-disallowed {
    margin: 0.5rem 1rem 0 1rem;
}

.signup-disallowed > * {
    margin-bottom: 2rem;
}

.signup-disallowed-multiline-message > * {
    margin-bottom: 0.8rem;
}

.signup-disallowed button {
    margin-bottom: 1.2rem;
    width: 100%;
}

.signup-disallowed-footer {
    text-align: center;
}