.cert-subscribe {
    margin-top: 10px;
    width: 100%;
}

.cert-subscribe-price-container {
    --subscription-manage-cert-name-size: 94px;
    align-items: center;
    border-radius: calc(var(--subscription-manage-cert-name-size) / 2);
    box-shadow: 1px 1px 5px var(--wnp-color-secondary-light);
    display: flex;
}

.cert-subscribe-price-cert-name {
    align-items: center;
    background-color: #ffffff;
    border-style: solid;
    border-width: 3px;
    border-radius: calc(var(--subscription-manage-cert-name-size) / 2);
    box-shadow: 1px 1px 5px var(--wnp-color-secondary-light);
    display: flex;
    font-size: 15px;
    height: var(--subscription-manage-cert-name-size);
    justify-content: center;
    width: var(--subscription-manage-cert-name-size);
}

.cert-subscribe-price-content {
    margin: 0 auto;
    padding-right: calc(var(--subscription-manage-cert-name-size) / 2);
}

.cert-subscribe-price-top-text-container {
    align-items: flex-end;
    display: flex;
}

.cert-subscribe-price-active {
    align-items: center;
    display: flex;
    font-size: 40px;
    line-height: 26px;
}

.cert-subscribe-price-active > span {
    font-size: 20px;
    margin-bottom: 7px;
}

.cert-subscribe-price-original {
    align-items: center;
    color: var(--wnp-color-secondary-light);
    display: flex;
    font-size: 32px;
    line-height: 26px;
    margin-right: 5px;
}

.cert-subscribe-price-original > span {
    font-size: 20px;
    margin-bottom: 7px;
}

.cert-subscribe-price-original > .cert-subscribe-price-original-number {
    font-size: 32px;
    text-decoration: line-through;
}

.cert-subscribe-price-interval {

}

.cert-subscribe-price-interval > span {
    margin: 0 3px;
}

.cert-subscribe-price-promo-name {
    color: var(--wnp-color-correct);
    margin-top: 8px;
}

.cert-subscribe-feature-list {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
}

.cert-subscribe-feature-item {
    align-items: center;
    display: flex;
    margin: 5px 0;
}

.cert-subscribe-feature-icon {
    margin-right: 5px;
}

.cert-subscribe-feature-icon > svg {
    fill: var(--wnp-color-correct);
}

.cert-subscribe-hr {
    background-color: var(--wnp-color-secondary-extralight);
    border: none;
    height: 1px;
}

.cert-subscribe-promo-code-form {
    margin-top: 35px;
    width: 100%;
}
.cert-subscribe-promo-code-form-row {
    display: flex;
    width: 100%;
}


.cert-subscribe-payment-method-form {
    margin-top: 35px;
}

.cert-subscribe-payment-method-form-label {
    margin-bottom: 8px;
}

.cert-subscribe-change-payment-method-container {
    display: flex;
    margin-top: 35px;
}

.cert-subscribe-change-payment-method-card {
    align-items: center;
    display: flex;
    margin-right: 10px;
}

.cert-subscribe-change-payment-method-card > img {
    height: 45px;
    margin-right: 10px;
}

.cert-subscribe-change-payment-method-card-exp {
    font-size: 14px;
}

.cert-subscribe-payment-action-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    width: 100%;
}

.cert-subscribe-payment-action-buttons > button {
    margin: 10px 0;
}