.signup-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  max-width: 340px;
  width: 80%;
}

.signup-form > * {
  margin: 10px 0 !important;
  width: 100%;
}

.signup-success {
  text-align: center;
}

.signup-success > p {
  margin-top: 15px;
  width: 90%;
}

.password-requirements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.password-requirements > p {
  font-size: 0.95rem;
  margin-bottom: 0.2rem;
}
.password-requirements > svg {
  position: relative;
  top: 10px;
  /* font-size: 0.1rem; */
}
.password-requirements ul > li {
  list-style-type: none;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.password-requirements ul > li > span {
  padding-left: 0.3rem;
}

.pass-validation-true {
  color: black;
}
.pass-validation-false {
  color: #a3a3a3;
}
.terms-and-policy {
  font-size: 0.9rem;
  text-align: center;
}
.terms-and-policy > a {
  font-size: 0.9rem;
}
