.practice-qa-answer-container {
    display: flex;
    flex-direction: column;
}

.practice-qa-answer-button-container, .practice-qa-next-button-container {
    margin: 30px 0;
    width: 100%;
}

.practice-qa-answer-button-container > button, .practice-qa-next-button-container > button {
    width: 100%;
}
