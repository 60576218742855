.subscription-manage-cert-container {
  display: flex;
  --subscription-manage-cert-name-size: 82px;
  flex-direction: column;
  align-items: center;
}

.subscription-manage-cert {
  /* align-items: center; */
  display: flex;
  margin: 10px 0;
}

.subscription-manage-cert-name {
  align-items: center;
  background-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  border-radius: calc(var(--subscription-manage-cert-name-size) / 2);
  box-shadow: 2px 2px 5px var(--wnp-color-secondary-light);
  display: flex;
  font-size: 13px;
  height: var(--subscription-manage-cert-name-size);
  justify-content: center;
  margin-right: calc(0px - calc(var(--subscription-manage-cert-name-size) / 4));
  position: relative;
  width: var(--subscription-manage-cert-name-size);
  z-index: 100; 
}
.subscription-manage-cert-name-subscribed {
  position: relative;
  bottom: 0px;
}
/* 
.subscription-manage-cert-name-loader {
  --subscription-manage-cert-name-loader-size: 10px;
  margin-left: calc(
    0px - var(--subscription-manage-cert-name-size) -
      var(--subscription-manage-cert-name-loader-size)
  );
  margin-top: calc(
    0px - calc(var(--subscription-manage-cert-name-loader-size) / 1)
  );
  margin-bottom: calc(
    0px - calc(var(--subscription-manage-cert-name-loader-size) / 1)
  );
  position: relative;
  left: 25px;
  top: 4px;
} */
/* .subscription-manage-cert-name-loader-unsubscribe {
  bottom: 100px;
} */
.subscription-manage-cert-name-loader > span {
  color: var(--wnp-color-primary) !important;
  height: calc(
    var(--subscription-manage-cert-name-size) +
      var(--subscription-manage-cert-name-loader-size)
  ) !important;
  width: calc(
    var(--subscription-manage-cert-name-size) +
      var(--subscription-manage-cert-name-loader-size)
  ) !important;
}

.subscription-manage-cert-content {
  align-items: center;
  box-shadow: 1px 1px 5px var(--wnp-color-secondary-light);
  border-radius: 4px;
  display: flex;
  justify-content: flex-end;
  margin-left: calc(0px - calc(var(--subscription-manage-cert-name-size) / 3));
  padding: 15px;
  width: 17rem;
}
.subscription-manage-cert-content-subscribed {
  justify-content: center;
}

.subscription-manage-cert-content > button {
  width: 11rem;
}
.subscription-manage-cert-content-subscribed
  > .subscription-manage-cert-subscribed-button
  > button {
  width: 100%;
}

.subscription-manage-cert-content-details > div {
  display: flex;
  flex-direction: column;
  padding-left: 2.4rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}
.subscription-manage-cert-content-subscribed {
  width: 100%;
}

.subscription-manage-modal {
  padding: 20px;
  text-align: center;
}

.subscription-manage-modal-button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}
.subscription-manage-modal-unsubscribe p {
  margin-bottom: 1rem;
}

.change-payment-card-button {
  margin: 2rem 0;
}

.subscription-price {
  font-size: 1.2rem;
}

.subscription-manage-change-payment-method-container {
  box-shadow: 1px 1px 5px var(--wnp-color-secondary-light);
  border-radius: 4px;
  margin: 30px 0;
  padding: 15px;
}

.subscription-manage-change-payment-method-inner-container {
  display: flex;
}

.subscription-manage-change-payment-method-card {
  align-items: center;
  display: flex;
  margin-right: 10px;
}

.subscription-manage-change-payment-method-card > img {
  height: 45px;
  margin-right: 10px;
}

.subscription-manage-change-payment-method-card-exp {
  font-size: 14px;
}