.cert-subscribe-disallowed {
    margin-top: 0.5rem;
}

.cert-subscribe-disallowed > * {
    margin-bottom: 2rem;
}

.cert-subscribe-disallowed-multiline-message > * {
    margin-bottom: 0.8rem;
}

.cert-subscribe-disallowed button {
    margin-bottom: 1.2rem;
    width: 100%;
}

.cert-subscribe-disallowed-footer {
    text-align: center;
}