.practice .MuiTabs-root {
    width: 100%;
    border-bottom: 1px solid var(--wnp-color-secondary-extralight);
    max-width: 650px;
    position: sticky;
    top: var(--topbar-height);
    background-color: #fff;
    z-index: 100;
}

.practice-tab-content {
    margin-top: 30px;
    width: 100%;
}
