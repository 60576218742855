:root {
  /* not in scope, but variable is needed in other comps. Maybe improve later */
  --topbar-height: 70px;
  --dropdown-height: 2.5rem;
}

.topbar-container {
  height: var(--topbar-height);
  width: 100%;
}

.topbar {
  /* background-image: linear-gradient(
    150deg, 
        var(--wnp-color-primary), 
        var(--wnp-color-primary), 
        var(--wnp-color-primary-light), 
        var(--wnp-color-primary-extralight)
        ); */
  background-color: #fff;
  box-shadow: 0 0 5px var(--wnp-color-secondary);
  display: flex;

  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  height: var(--topbar-height);
  padding: 0 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.topbar-left-content {
  display: flex;
  flex-direction: column;
}

.topbar-left-content .topbar-left-content-top {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.topbar-left-content .topbar-left-content-top > div {
  display: flex;
  position: relative;
  align-items: center;
}
.topbar-logo {
  height: calc(var(--topbar-height) / 2);
}

.topbar-logo.topbar-logo-small {
  display: none;
}

.topbar-menu-toggle-button {
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--wnp-color-font);
  border: 1.5px solid var(--wnp-color-font);
  border-radius: calc(var(--topbar-height) / 1.8);
  cursor: pointer;
  display: flex;
  height: calc(var(--topbar-height) / 1.8);
  justify-content: center;
  width: 65px;
}

.topbar-menu-toggle-button:active {
  border-color: var(--wnp-color-primary);
  border-width: 2px;
  box-shadow: 0px 0px 1px var(--wnp-color-secondary-light);
}

.topbar-menu-toggle-button > img {
  height: 22px;
}

.topbar-menu-mobile {
  --menu-background-opacity: 0.5;
  --menu-background-blur: 2px;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  top: var(--topbar-height);
  width: 100%;
  z-index: 980;
}

.topbar-menu-mobile-inner {
  background: #fff;
  box-shadow: 0 0 5px var(--wnp-color-secondary);
  padding-top: 9px;
  position: relative;
  width: 180px;
  z-index: 990;
}

/* ############################################################### */
/* open animation */
/* 
    the rgba background color is based on the --wnp-color-secondary-extralight: #d5d7d8; 
    had to make exception of not using variable here, because opacity changes the child element, easier to do the rgba way
*/
.topbar-menu-mobile-open {
  animation: topbar-menu-mobile-opacity-open 0.25s linear 0s 1 forwards;
}

.topbar-partnership-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.3rem;
}

.topbar-partner-logo {
  height: calc(var(--topbar-height) / 3.7);
}

.topbar-partnership-logo-container > span {
  text-align: center;
  font-size: 0.7rem;
  padding-right: 0.2rem;
  text-align: center;
}
.topbar-partnership-logo-container > div {
  display: flex;
  align-content: center;
  justify-content: center;
  font-weight: 600;
  color: rgb(39, 39, 39);
  font-size: 0.8rem;
}
.topbar-partnership-logo-container > div > span {
  padding-left: 2px;
  font-family: "Montserrat", sans-serif;
}

@keyframes topbar-menu-mobile-opacity-open {
  from {
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
    background-color: rgba(213, 215, 216, 0);
  }
  to {
    -webkit-backdrop-filter: blur(var(--menu-background-blur));
    backdrop-filter: blur(var(--menu-background-blur));
    background-color: rgba(213, 215, 216, var(--menu-background-opacity));
  }
}
.topbar-menu-mobile-open > .topbar-menu-mobile-inner {
  animation: topbar-menu-mobile-slide-open 0.25s linear 0s 1 forwards;
}
@keyframes topbar-menu-mobile-slide-open {
  from {
    right: -100%;
  }
  to {
    right: 0%;
  }
}
/* ############################################################### */
/* close animation */
.topbar-menu-mobile-close {
  animation: topbar-menu-mobile-opacity-close 0.25s linear 0s 1 forwards;
}
@keyframes topbar-menu-mobile-opacity-close {
  0% {
    -webkit-backdrop-filter: blur(var(--menu-background-blur));
    backdrop-filter: blur(var(--menu-background-blur));
    background-color: rgba(213, 215, 216, var(--menu-background-opacity));
  }
  99% {
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
    background-color: rgba(213, 215, 216, 0);
    margin-right: 0;
  }
  100% {
    margin-right: -100%;
  }
}
.topbar-menu-mobile-close > .topbar-menu-mobile-inner {
  animation: topbar-menu-mobile-slide-close 0.25s linear 0s 1 forwards;
}
@keyframes topbar-menu-mobile-slide-close {
  from {
    right: 0%;
  }
  to {
    right: -100%;
  }
}
/* ############################################################### */

.topbar-menu-mobile-container {
  text-align: center;
}

.topbar-menu-mobile-inner-link {
  padding: 15px 20px;
}
.topbar-menu-mobile-inner-link-selected {
  background-color: #edf9ff;
  border-left: 5px solid var(--wnp-color-primary);
}
.topbar-menu-mobile-inner-link-selected > a {
  margin-left: -5px;
}
.topbar-menu-mobile-inner-link > a {
  font-size: 18px;
}

.topbar-menu-mobile-inner-button {
  padding: 10px 20px;
}

.topbar-menu-desktop-container {
  display: none;
}

@media only screen and (min-width: 330px) {
  :root {
    --dropdown-height: 2rem;
  }
  #cert-select-menu-container > div {
    height: var(--dropdown-height);
  }
  .topbar-logo.topbar-logo-small {
    display: initial;
    height: calc(var(--topbar-height) / 3);
  }
  .topbar-middle-content {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 460px) {
  :root {
    --dropdown-height: 2.5rem;
  }
  #cert-select-menu-container > div {
    position: relative;
    top: 10px;
    height: var(--dropdown-height);
  }

  .topbar-logo.topbar-logo-small {
    height: calc(var(--topbar-height) / 2);
  }
  .topbar-middle-content {
    margin-left: 20px;
  }
  .topbar-partnership-logo-container > div > span {
    padding-left: 2px;
    font-family: "Montserrat", sans-serif;
  }
  .topbar-partner-logo {
    height: calc(var(--topbar-height) / 3.8);
  }

  .topbar-partnership-logo-container > span {
    text-align: center;
    font-size: 0.8rem;
    padding-right: 0.2rem;
    text-align: center;
  }
  .topbar-partnership-logo-container > div {
    display: flex;
    align-content: center;
    justify-content: center;
    font-weight: 600;
    color: rgb(39, 39, 39);
    font-size: 0.9rem;
  }
}

@media only screen and (min-width: 780px) {
  :root {
    --topbar-height: 70px;
    --dropdown-height: 2.5rem;
  }

  .topbar {
    padding: 0 15px 0 20px;
  }

  .topbar-middle-content {
    margin-left: 30px;
  }

  .topbar-menu-mobile-container {
    display: none;
  }

  .topbar-menu-desktop-container {
    align-items: center;
    display: flex;
    height: 100%;
  }

  .topbar-menu-desktop-link {
    align-items: center;
    display: flex;
    height: 100%;
  }
  .topbar-menu-desktop-link,
  .topbar-menu-desktop-button {
    padding: 0 15px;
  }
  .topbar-menu-desktop-link-selected {
    border-bottom: 5px solid var(--wnp-color-primary);
  }
  .topbar-menu-desktop-link-selected > a {
    margin-bottom: -5px;
  }
  .topbar-menu-desktop-link > a {
    font-size: 18px;
  }
}
