.practice-history-question-accordian-summary {
    align-items: flex-start;
    display: flex;
}

.practice-history-question-correct-icon > svg,
.practice-history-question-incorrect-icon > svg {
    height: 20px;
    width: 20px;
}

.practice-history-question-correct-icon > svg {
    color: var(--wnp-color-correct)
}

.practice-history-question-incorrect-icon > svg {
    color: var(--wnp-color-incorrect)
}

.practice-history-question-accordian-question-text {
    text-align: left;
    margin-left: 8px;
}

.practice-history-question-accordian-question-number {
    font-size: 20px;
    line-height: 16px;
}

.practice-history-question-pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    width: 100%;
}