.practice-answer {
    --practice-answer-min-height: 46px;
    align-items: center;
    border-radius: calc(var(--practice-answer-min-height) / 2);
    border: 1px solid var(--wnp-color-secondary-extralight);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    min-height: var(--practice-answer-min-height);
    padding: 10px 11px 10px 15px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.practice-answer-option-text {
    align-items: center;
    display: flex;
    width: 90%;
}

.practice-answer-option-key {
    margin-right: 10px;    
}

.practice-answer-option-bubble {
    width: calc(var(--practice-answer-min-height) / 1.8);
    height: calc(var(--practice-answer-min-height) / 1.8);
    border-radius: calc(var(--practice-answer-min-height) / 3.6);
    border: 1px solid var(--wnp-color-secondary-light);
}

.practice-answer-option-bubble-correct, .practice-answer-option-bubble-incorrect {
    display: none;
}
.practice-answer-option-bubble-correct > svg {
    color: var(--wnp-color-correct)
}
.practice-answer-option-bubble-incorrect > svg {
    color: var(--wnp-color-incorrect)
}

.practice-answer-selected, .practice-answer-correct, .practice-answer-incorrect {
    border-width: 2px;
    margin-top: 14px;
    margin-bottom: -1px;
    padding: 10px 10px 10px 14px;
}

.practice-answer-selected {
    border-color: var(--wnp-color-primary);
}
.practice-answer-selected .practice-answer-option-bubble {
    /* border-width: 2px; */
    border-color: var(--wnp-color-primary);
    padding: 2px;
    /* margin: -1px; */
}
.practice-answer-selected .practice-answer-option-bubble-inner {
    background-color: var(--wnp-color-primary);
    height: 100%;
    width: 100%;
    border-radius: calc(var(--practice-answer-min-height) / 3.4);
}

.practice-answer-disabled {
    background-color: #fafafa;
    cursor: default;
}

.practice-answer-disabled .practice-answer-option-bubble {
    border-color: var(--wnp-color-secondary-extralight);
}

.practice-answer-disabled .practice-answer-option-bubble.practice-answer-option-bubble-no-empty {
    border-color: #fafafa;
}

.practice-answer-correct .practice-answer-option-bubble, 
.practice-answer-incorrect .practice-answer-option-bubble {
    border: none;
    padding: 0;
}

.practice-answer-correct {
    background-color: var(--wnp-color-correct-extralight);
    border-color: var(--wnp-color-correct);
    cursor: default;
}
.practice-answer-correct .practice-answer-option-bubble-correct {
    display: initial;
}

.practice-answer-incorrect {
    background-color: var(--wnp-color-incorrect-extralight);
    border-color: var(--wnp-color-incorrect);
    cursor: default;
}
.practice-answer-incorrect .practice-answer-option-bubble-incorrect {
    display: initial;
}