.practice-domain-history-list-item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    width: 100%;
}

.practice-domain-history-list-item-data-list {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0 10px;
}
