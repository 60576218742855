.cert-select-menu-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.cert-select-menu-add {
    color: var(--wnp-color-primary);
    margin-left: 10px;
}

.cert-select-modal {
    background-color: #fff;
    margin: 20vh auto 0 auto;
    min-width: 280px;
    max-width: 500px;
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px 20px;
    text-align: center;
}

.cert-select-modal > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
}
.cert-select-modal > div > div {
    margin: 10px 0;
    width: 100%;
    max-width: 280px;
}
.cert-select-modal > div > div > button {
    width: 100%;
}

.cert-select-menu-popover-content {
    align-items: center;
    display: flex;
    padding: 10px 12px;
}

.cert-select-menu-popover-close-icon {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-left: 10px;
}

@media only screen and (min-width: 480px) {
    .cert-select-modal {
        padding: 30px;
    }
}