.practice-test-cancel-popover {
    padding: 20px;
    text-align: center;
}

.practice-test-cancel-popover-button-container {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}

.practice-test-time-remaining {
    background-color: var(--wnp-color-primary-extralight);
    border-radius: 26px;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
    width: 100%;
}

.practice-test-time-remaining-clock {
    width: 55px;
    display: inline-block;
    text-align: left;
}